.suspense-loader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../../images/Loader_Ring.gif') 50% 50% no-repeat rgb(249,249,249);
}

.chat-spinner{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}