@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    // top: -8px;
    // left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(35, 84, 176, 0.807);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
}

.mx-hospot-ripple {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    //   background: #FFFFFF;
    //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    span {
        position: relative;
        font-size: 72px;
        top: 5px;
        left: -5px;
    }
  .hotspot-inner {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    // top: -8px;
    // left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    //border: 8px solid rgba(35, 84, 176, 0.807);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 3s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
  }

  .hotspot-outer {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    // top: -8px;
    // left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    //border: 8px solid rgba(35, 84, 176, 0.807);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
  }
    /*&::after {
        @include rings(3s, 0s);
    }
    &::before {
        @include rings(3s, 0.5s);
    }*/
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

.no-left-content {
    float: none;
    width: auto;
}

.RightTopStyle1.RightTopStyle {
    top: 80px;
}

.PeopleChatPopup1 {
    top: 80px;    
    height: calc(100vh - 153px);
}
.mh-cursor-pointer {
    cursor: pointer;
}